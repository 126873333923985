
import Card from "@/components/molecules/dashboard/Card.vue";
import { defineComponent } from "vue";
import "vue-json-pretty/lib/styles.css";
import { useWallet } from "@/plugins/solana-wallets-vue";

export default defineComponent({
  name: "MyCollectionSection",
  data() {
    return {
      data: {},
      dataNames: {
        myCollection: "My Collection",
      },
      creator: "",
      allMyNFTs: null,
    };
  },
  components: {
    Card,
  },
  mounted() {
    this.getCreator();
  },
  methods: {
    getCreator() {
      this.$store.dispatch("getNFTs/getCreator").then(
        (response) => {
          this.creator = response.creator;
          this.getAllNft();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    goToCollectionDetails(id) {
      this.$router.push(`/dashboard/collection-details/${id}`);
      localStorage.setItem("my-nft", JSON.stringify(id));
    },
    async getAllNft() {
      const { publicKey } = useWallet();
      const NFTs = require("@/plugins/solana-nft-tools");
      const web3 = require("@solana/web3.js");
      const conn = new web3.Connection(
        web3.clusterApiUrl("devnet"),
        "confirmed"
      );
      this.allMyNFTs = await NFTs.getNFTsByOwner(
        conn,
        publicKey.value?.toString()
      );
    },
  },
});
