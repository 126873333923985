import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b7e0dc9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-collection-section" }
const _hoisted_2 = { class: "my-collection-section__title" }
const _hoisted_3 = { class: "my-collection-section__cards-wrap" }
const _hoisted_4 = ["n"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "preloader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(this.dataNames.myCollection), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.allMyNFTs, (n) => {
        return (_openBlock(), _createElementBlock("div", {
          key: n.mint,
          n: n,
          class: "my-collection-section__cards"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(n.creators, (creator) => {
            return (_openBlock(), _createElementBlock("div", {
              key: creator.id
            }, [
              (creator.address == this.creator)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_Card, {
                      name: n.name,
                      imgCatalog: n.image,
                      attributes: n.attributes,
                      onClick: ($event: any) => (_ctx.goToCollectionDetails(n.mint))
                    }, null, 8, ["name", "imgCatalog", "attributes", "onClick"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ], 8, _hoisted_4))
      }), 128))
    ]),
    (this.allMyNFTs === null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6))
      : _createCommentVNode("", true)
  ]))
}